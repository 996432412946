<template>
  <div ref="main" class="m-main-wrap" v-loading="loadList">
    <div ref="topDom">
      <!-- 搜索 start -->
      <div class="flex-l flex-wrap lhx40">
        <el-input
          class="w200 mr10 mb20"
          placeholder="请输入商品名稱/ID"
          clearable
          v-model="filters.title"
          @clear="search"
          @keyup.enter.native="search"
        ></el-input>
        <el-select
          class="w200 mr10 mb20"
          v-model="filters.rid"
          filterable
          placeholder="請選擇商品分類"
          clearable
          @clear="search"
          @keyup.enter.native="search"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="filters.nid"
          class="w200 mr10 mb20"
          placeholder="商品新舊程度"
          clearable
          @clear="search"
          @keyup.enter.native="search"
        >
          <el-option
            v-for="item in degrees"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-input
          class="w120 mr10 mb20"
          placeholder="最低價格"
          clearable
          v-model="filters.min"
          type="number"
          @clear="search"
          @keyup.enter.native="search"
        ></el-input>
        <span class="mr10 mb20">-</span>
        <el-input
          class="w120 mr10 mb20"
          placeholder="最高價格"
          clearable
          v-model="filters.max"
          type="number"
          @clear="search"
          @keyup.enter.native="search"
        ></el-input>
        <el-checkbox v-model="filters.offline" :true-label="1" false-label=""
          >面交</el-checkbox
        >
        <el-checkbox
          v-model="filters.mail"
          :true-label="1"
          false-label=""
          class="mr20"
          >郵寄及速遞</el-checkbox
        >
        <div>
          <el-button class="mb20" @click="resetFilters">重置</el-button>
          <el-button class="mb20" type="primary" @click="search"
            >搜尋</el-button
          >
          <el-button class="mb20" type="primary" @click="exportData"
            >導出</el-button
          >
        </div>
      </div>
    </div>
    <!-- 内部侧边栏 -->
    <el-container>
      <el-main>
        <!-- 表格 -->
        <el-table
          :data="tableData.data"
          style="width: 100%"
          :height="tableHeight"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-form label-position="top" inline>
                <el-form-item label="用戶ID" class="mr20">
                  <span>
                    <a
                      class="el-link el-link--primary"
                      target="_blank"
                      :href="constants.clientUrl+'/userDetails?userId='+props.row.tuid"
                      >{{ props.row.uid }}</a
                    >
                  </span>
                </el-form-item>
                <el-form-item label="商品分類" class="mr20">
                  <span>{{ props.row.rid }}</span>
                </el-form-item>
                <el-form-item label="交易方式" class="mr20">
                  <span>{{ props.row.jyfs }}</span>
                </el-form-item>
                <el-form-item label="私訊數量" class="mr20">
                  <span>{{ props.row.chat }}</span>
                </el-form-item>
                <el-form-item label="國家/區域" class="mr20">
                  <span>{{ props.row.region }}</span>
                </el-form-item>
                <el-form-item label="成交時間" class="mr20">
                  <span>{{ props.row.deal_time | formatTime }}</span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column width="60" label="NO.">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column label="商品ID" width="160">
            <template slot-scope="scope">{{ scope.row.randname }}</template>
          </el-table-column>

          <el-table-column label="商品信息" width="260">
            <a :href="constants.clientUrl+'/goodsDetails?id='+scope.row.id" target="_blank" slot-scope="scope">
              <div class="flex-l flex-mid">
                <div>
                  <el-image
                    v-if="scope.row.image"
                    style="width: 60px; height: 60px"
                    :src="constants.imgUrl + scope.row.image"
                    fit="contain"
                  ></el-image>
                </div>
                <div class="w200 ml20">
                  <div>{{ scope.row.title }}</div>
                  <div class="flex-bt color-999">
                    <span>{{ scope.row.nid }}</span>
                    <span>HK${{ scope.row.price }}</span>
                  </div>
                </div>
              </div>
            </a>
          </el-table-column>
          <el-table-column label="like數" sortable prop="like" width="100">
            <template slot-scope="scope">
              {{ scope.row.like }}
            </template>
          </el-table-column>
          <el-table-column
            width="120"
            label="自然曝光量"
            sortable
            prop="nexposure"
          ></el-table-column>
          <el-table-column
            width="120"
            label="付費曝光量"
            sortable
            prop="bexposure"
          ></el-table-column>
          <el-table-column
            label="點擊數"
            sortable
            prop="traffic"
            width="90"
          ></el-table-column>
          <el-table-column label="商品狀態" width="100" prop="state" sortable>
            <template slot-scope="scope">
              <el-tag v-if="scope.row.state == 1">出售中</el-tag>
              <el-tag type="success" v-if="scope.row.state == 2"
                >交易完成</el-tag
              >
              <el-tag type="danger" v-if="scope.row.state == 3">已下架</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="發佈時間" prop="create_time" sortable width="140">
            <template slot-scope="scope">
              {{ scope.row.create_time | formatTime }}
            </template>
          </el-table-column>
          <el-table-column label="封禁狀態" prop="status">
            <!-- 1: "正常", 2: "已封禁", -->
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.status == 1">正常</el-tag>
              <el-tag type="danger" v-if="scope.row.status == 2">已封禁</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="備註" width="100">
            <template slot-scope="scope">
              <el-popover
                v-if="scope.row.note"
                placement="top-start"
                title="備註"
                width="200"
                trigger="hover"
                :content="scope.row.note"
              >
                <div class="c-note" slot="reference">{{ scope.row.note }}</div>
              </el-popover>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-link
                class="mr20"
                type="danger"
                v-if="scope.row.status == 1"
                @click="unPublish(scope.$index)"
                >封禁</el-link
              >
              <el-link
                class="mr20"
                type="danger"
                v-if="scope.row.status == 2"
                @click="publish(scope.$index)"
                >解封</el-link
              >
              <el-link type="primary" @click="editNote(scope.$index)"
                >备注</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 ｜ 批量移动-->
        <div ref="btmDom" class="flex-r flex-mid pb10 pt10">
          <el-pagination
            background
            hide-on-single-page
            :page-size="tableData.per_page"
            :current-page="tableData.current_page"
            :total="tableData.total"
            @current-change="changePage"
          ></el-pagination>
        </div>
      </el-main>
    </el-container>
    <el-dialog
      width="600px"
      title="備註"
      :append-to-body="true"
      :modal-append-to-body="true"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <div>
        <el-form ref="ruleForm" :model="form">
          <el-form-item label="" prop="note">
            <el-input
              v-model="form.note"
              type="textarea"
              placeholder="请输入備註"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="confirm" :loading="loadingNote"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { calTableHeight } from '@/common/tool'
export default {
  data() {
    return {
      loadList: false,
      loadingNote: false,
      curItem: {},
      curIndex: -1,
      showDialog: false,
      filters: {
        title: '',
        rid: '',
        nid: '',
        min: '',
        max: '',
        offline: '',
        mail: '',
      },
      form: {},
      options: [],
      tableData: {
        data: [],
        current_page: 1,
        per_page: 15,
        total: 0,
      },
      tableHeight: 100, //表格高度
      degrees: [],
    }
  },
  created() {
    this.getDegrees()
    this.getDataList()
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = calTableHeight(this)
    })
  },
  methods: {
    async getDataList(page = 1) {
      this.loadList = true
      let res = await this.api.goods.getGoods({
        page,
        ...this.filters,
      })
      if (res && res.code == 0) {
        this.tableData = res.data
      }
      this.loadList = false
    },
    async getDegrees() {
      let res = await this.api.goods.getGoodsDegrees()
      if (res && res.code == 0) {
        res.data = res.data ? res.data : {}
        this.degrees = res.data.newold
        this.options = res.data.rule
      }
    },
    editNote(index) {
      this.curIndex = index
      this.curItem = this.tableData.data[index]
      this.form = { ...this.curItem }
      this.showDialog = true
    },
    /**
     * @method: resetFilters 重置筛选条件
     */
    resetFilters() {
      this.filters = {
        title: '',
        rid: '',
        nid: '',
        min: '',
        max: '',
        offline: '',
        mail: '',
      }
    },
    search() {
      if (!isNaN(this.filters.max) && !isNaN(this.filters.min)) {
        if (
          this.filters.max &&
          this.filters.min &&
          this.filters.max < this.filters.min
        ) {
          this.$message({
            message: '最高價格不能小于最低價格',
            type: 'warning',
          })
          return
        }
      }
      this.getDataList()
    },
    changePage(pageNum) {
      this.getDataList(pageNum)
    },
    exportData() {
      this.getExportData()
    },
    async getExportData() {
      let res = await this.api.goods.exportGoods()
      var a = document.createElement('a')
      a.href = res.data
      document.body.append(a)
      a.click()
      document.body.removeChild(a)
    },
    closeDialog() {
      this.loadingNote = false
      this.showDialog = false
      this.$refs['ruleForm'].resetFields()
    },
    confirm() {
      this.saveNote()
    },
    async saveNote() {
      this.loadingNote = true
      let id = this.tableData.data[this.curIndex].id
      let res = await this.api.goods.addNode(id, this.form.note)
      if (res && res.code == 0) {
        this.$set(this.tableData.data[this.curIndex], 'note', this.form.note)
        this.closeDialog()
        this.$message({
          message: '備註成功',
          type: 'success',
        })
        this.getDataList()
      }
      this.loadingNote = false
    },
    unPublish(index) {
      this.$confirm(
        '封禁商品後，商品將不會在網站中展出，且用戶不可主動解除商品的封禁狀態，您確定要封禁該商品嗎？',
        '封禁商品',
        {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          this.doUnPublish(index)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    publish(index) {
      this.$confirm(
        '解封商品後，商品將恢復正常狀態，您確定要解封該商品嗎？',
        '解封商品',
        {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          this.doPublish(index)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    async doUnPublish(index) {
      let id = this.tableData.data[index].id
      let res = await this.api.goods.switchGoods(id, 2)
      if (res && res.code == 0) {
        this.$set(this.tableData.data[index], 'status', 2)
        this.$message({
          message: '封禁成功',
          type: 'success',
        })
      }
    },
    async doPublish(index) {
      let id = this.tableData.data[index].id
      let res = await this.api.goods.switchGoods(id, 1)
      if (res && res.code == 0) {
        this.$set(this.tableData.data[index], 'status', 1)
        this.$message({
          message: '解封成功',
          type: 'success',
        })
      }
    },
  },
}
</script>
<style scoped>
/deep/.el-form-item__label {
  color: #999;
}
/deep/.el-form--inline .el-form-item {
  padding-right: 20px;
}
/deep/.el-table__fixed-body-wrapper{
  top: 59px!important;
}
</style>
